<template>
<v-container>
    <v-row>
      <v-col cols="12" class="text-center">
          <header-vue/>
      </v-col>

      <v-col
        class="mb-5"
        cols="12" sm="12" md="6" lg="9"
      >
            <v-card>
                <v-card-title>
                    RECOVER PASSWORD
                </v-card-title>
                <v-card-subtitle>
                    Enter your Email address and a Reset CODE will be emailed to you in approx. 5 minutes time
                </v-card-subtitle>
                <v-card-text>
                    <v-form
                        v-if="nextScreen == false"
                    >
                        <v-row>
                            <v-col md="12">
                                <v-text-field
                                v-model="email"
                                :counter="50"
                                outlined
                                :rules="[ v => /.+@.+\..+/.test(v) || 'E-mail must be valid']"
                                label="E-mail Address"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col md="12">
                                <v-btn
                                color="success"
                                class="mr-4"
                                @click="sendToken"
                                >
                                Send Reset Token
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-form
                        v-else
                    >
                        <v-row>
                            <v-col md="6" cols="12">
                                <v-text-field
                                v-model="form.password"
                                :counter="15"
                                outlined
                                :rules="passwordRules"
                                label="New Password"
                                type="password"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col md="6" cols="12">
                                <v-text-field
                                v-model="form.password_confirmation"
                                :counter="15"
                                outlined
                                :rules="passwordRules"
                                label="Re-enter New Password"
                                type="password"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col md="12">
                                <v-text-field
                                v-model="form.token"
                                :counter="4"
                                outlined
                                :rules="numberRules"
                                label="Password Token"
                                required
                                ></v-text-field>
                            </v-col>
                            <v-col md="12">
                                <v-btn
                                small
                                color="success"
                                class="mr-4"
                                @click="updatePassword"
                                >
                                Update Password
                                </v-btn>
                                <v-btn
                                small
                                text
                                class="mr-4"
                                @click="nextScreen = false"
                                >
                                Restart Recovery Process
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
      </v-col>
      <v-col
        class="mb-5"
        cols="12" sm="12" md="6" lg="3"
      >
        <guest-menu-card/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GuestMenuCard from '../../components/GuestMenuCard.vue'
import axios from 'axios';
import HeaderVue from '../../components/Header.vue';
  export default {
    name: 'RecoverPassword',
    components: {
        GuestMenuCard,
        HeaderVue,
    },
    mounted() {
       if (this.authToken) {
           this.$router.push('dashboard')
       }
    },
    data: () => ({
        authToken:sessionStorage.getItem('authToken') ?? null,
        token:null,
        email: null,
        form: {},
        nextScreen: false,
        passwordRules: [
            v => !!v || 'Password is required',
            v => (v && v.length >= 8) || 'Password must not be less than 8 characters',
        ],
        numberRules: [
            v => !!v || 'Password Token is required',
            v => (v && v.length == 4) || 'Code must be 4 digits',
        ],
    }),
    methods: {
        updatePassword () {
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_AUTHURL+'/password/reset?email='+this.email,this.form,
            {
                headers: 
                    {
                        'Device-Referrer-Id': process.env.VUE_APP_DEVICEID
                    }
            })
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                });                
                this.nextScreen = false
                this.$router.push('login')
            })
            .catch((error) => {
                console.log(error.response)
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
            });
        },
        sendToken () {
            let loader = this.$loading.show()
            axios.post(process.env.VUE_APP_AUTHURL+'/password/recovery?email='+this.email,
            {})
            .then((response) => {
                this.$toast.success(response.data.message, {
                    timeout: 5000
                });
            })
            .catch((error) => {
                this.$toast.error(error.response ? error.response.data.message : 'Network Error, Please try again few minutes', {
                    timeout: 5000
                });
            })
            .finally(() => {
                loader.hide()
                this.nextScreen = true
            });
        },
    },
  }
</script>
